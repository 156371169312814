
export default {
    props: {
        model: {
            type: Boolean,
            default: false,
        },
        linkUrl: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        showDialog: false,
    }),
    watch: {
        model(n, o) {
            this.showDialog = n
        },
    },
    mounted() {
        this.showDialog = this.model
    },
    methods: {
        closeImage() {
            this.showDialog = false
            this.$emit('reject', this.showDialog)
        },
    },
}
