
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            isMobileScreen: false,
            homeSliderIntersect: false,
            instructionTabIntersect: false,
            featureGameIntersect: false,
            totalJackpotIntersect: false,
            promotionIntersect: false,
            activityIntersect: false,
            recentWinnerIntersect: false,
            featureArticleIntersect: false,
        }
    },
    computed: {
        ...mapGetters({
            desktopBackground: 'cms/desktopBackground',
            mobileBackground: 'cms/mobileBackground',
            rightFloatingSupportIcon: 'cms/rightFloatingSupportIcon',
            rightFloatingMessageIcon: 'cms/rightFloatingMessageIcon',
        }),
    },
    mounted() {
        try {
            const session = window.sessionStorage
            const toggleFloating = session.getItem('toggleFloating') // Get toggle session that set from forgot password condition contact customer support
            if (toggleFloating) {
                if (
                    this.host &&
                    !this.isMain &&
                    this.rightFloatingMessageIcon.is_visible
                ) {
                    this.$nuxt.$emit('@toggleAgent', true) // Toggle for agent
                } else if (
                    this.isMain &&
                    this.rightFloatingSupportIcon.is_visible
                ) {
                    this.$nuxt.$emit('@toggleSupport', true) // Toggle support button if not hide from back office
                } else if (
                    this.isMain &&
                    this.rightFloatingMessageIcon.is_visible
                ) {
                    this.$nuxt.$emit('@toggleMessage', true) // Toggle message button if support button is hiden and message button still available
                }
            }
            session.removeItem('toggleFloating') // After check remove away, no need to kip it anymore
        } catch (error) {}
    },
    methods: {
        onResize() {
            if (process.browser) {
                this.isMobileScreen = window.innerWidth < 1300
                return null
            }
            this.isMobileScreen = false
        },
        onHomeSlideIntersect() {
            this.homeSliderIntersect = true
        },
        onInstructionTabIntersect() {
            this.instructionTabIntersect = true
        },
        onFeatureGameIntersect() {
            this.featureGameIntersect = true
        },
        onTotalJackpotIntersect() {
            this.totalJackpotIntersect = true
        },
        onPromotionIntersect() {
            this.promotionIntersect = true
        },
        onActivityIntersect() {
            this.activityIntersect = true
        },
        onRecentWinnerIntersect() {
            this.recentWinnerIntersect = true
        },
        onFeatureArticleIntersect() {
            this.featureArticleIntersect = true
        },
    },
}
