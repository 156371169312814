
import { mapState, mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            jackpot: 'cms/jackpot',
            jackpotVal: 'cms/jackpotVal',
        }),
        jackpotSlide() {
            const chunkSize = 4
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.jackpot?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.jackpot?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-jackpot-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 320) / 600
            this.imageHeight = height
        },
    },
}
