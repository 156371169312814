import { render, staticRenderFns } from "./VideoContent1And2.vue?vue&type=template&id=ca3b87f8&scoped=true"
import script from "./VideoContent1And2.vue?vue&type=script&lang=js"
export * from "./VideoContent1And2.vue?vue&type=script&lang=js"
import style0 from "./VideoContent1And2.vue?vue&type=style&index=0&id=ca3b87f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3b87f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2YoutubeEmbed: require('/var/app/components/Theme2/YoutubeEmbed.vue').default})
