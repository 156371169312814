
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            rootTitle: 0,
            button: 0,
            contentItem: {},
        }
    },
    computed: {
        ...mapGetters({
            introductionVideoContents: 'cms/introductionVideoContents',
        }),
    },
    watch: {
        introductionVideoContents(value) {
            // first check
            if (value.length > 0) {
                if (value[0].items.length > 0) {
                    this.contentItem = value[0]?.items[0]
                }
            }
        },
    },
    methods: {
        getYoutubeVideoLink(link) {
            if (!link) return
            const youtubeId = this.getYoutubeVideoId(link)
            return `https://www.youtube.com/embed/${youtubeId}` // ?autoplay=1
        },
    },
}
