
import { mapGetters } from 'vuex'
export default {
    name: 'VideoContent1And2',
    data() {
        return {
            tab: 0,
            introductionTab: 0,
            showVideoDialog: false,
            videoLink: '',
            windowWidth: 376,
        }
    },
    computed: {
        ...mapGetters({
            introductionVideoContents: 'cms/introductionVideoContents',
        }),
        isMini() {
            return this.windowWidth < 375
        },
    },
    methods: {
        setGlobalTabVal(val) {
            this.tab = val
            this.introductionTab = 0
        },
        open(link) {
            window.open(link, 'Google', 'width=550,height=600')
        },
        getYoutubeVideoLink(link) {
            if (!link) return
            const youtubeId = this.getYoutubeVideoId(link)
            return `https://www.youtube.com/embed/${youtubeId}` // ?autoplay=1
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
    },
}
