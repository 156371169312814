import { render, staticRenderFns } from "./HomeSlider.vue?vue&type=template&id=144d0ae9"
import script from "./HomeSlider.vue?vue&type=script&lang=js"
export * from "./HomeSlider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2Announcement: require('/var/app/components/Theme2/Announcement.vue').default})
