
import { mapGetters } from 'vuex'
export default {
    name: 'FloatingIcons',
    props: {
        icons: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        dialog: false,
    }),
    computed: {
        ...mapGetters({
            topDepositRanking: 'cms/topDepositRanking',
        }),
    },
    methods: {
        open(link) {
            window.open(link, 'Google', 'width=550,height=600')
        },
    },
}
