
import { mapGetters } from 'vuex'
export default {
    name: 'FeatureGame2',
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent1: 'cms/featuredContent1',
        }),
        featuredContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.featuredContent1?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(
                    this.featuredContent1?.items
                ).slice(i, i + chunkSize)
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-feature-game-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 400) / 600
            this.imageHeight = height
        },
    },
}
