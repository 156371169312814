
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            promotion: 'cms/promotion',
        }),
        promotionContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.promotion?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.promotion?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-promotion-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 240) / 600
            this.imageHeight = height
        },
    },
}
